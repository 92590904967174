<template>
    <div>
        <el-form :model="ruleForm" :label-position="labelPosition" :rules="rules" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="场地类型" prop="radioSiteType">
                        <el-radio-group class="siteType" v-model="ruleForm.radioSiteType">
                            <p><el-radio label="1">篮球场</el-radio></p>
                            <p><el-radio label="2">网球场</el-radio></p>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="场地名称" prop="cardName">
                        <el-input v-model="ruleForm.cardName" placeholder="请输入场地名称"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="是否开放">
                        <el-switch v-model="ruleForm.value"></el-switch>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="常规开放时间">
                        <el-collapse v-model="conventionTime" accordion>
                            <el-collapse-item v-for="(item, index) in weeks" :key="index" :title="item.openWeek"
                                :name="index">
                                <div style="position: relative;">
                                    <div v-for="(j, jIndex) in item.manageTimeSlotVOS" :key="jIndex + 'o'"
                                        style="margin-top: 3px;">
                                        <el-time-select style="width: 180px;" placeholder="起始时间" v-model="j.startTime"
                                            :clearable="false" :editable="false" :picker-options="{
                                                    // start: '08:00',
                                                    start: '08:00',
                                                    step: '00:30',
                                                    end: '22:00',
                                                    // minTime: (item.manageTimeSlotVOS[jIndex-1] && item.manageTimeSlotVOS[jIndex-1].endTime),
                                                    minTime: getMinTime(item.manageTimeSlotVOS[jIndex - 1] && item.manageTimeSlotVOS[jIndex - 1].endTime),
                                                    maxTime: j.endTime
                                                }">
                                        </el-time-select>
                                        <el-time-select style="width: 180px; " placeholder="结束时间" v-model="j.endTime"
                                            :clearable="false" :editable="false" :picker-options="{
                                                    start: '08:00',
                                                    step: '00:30',
                                                    end: '22:00',
                                                    minTime: j.startTime || item.manageTimeSlotVOS[jIndex - 1] && item.manageTimeSlotVOS[jIndex - 1].endTime,
                                                    maxTime: getMaxTime(item.manageTimeSlotVOS[jIndex + 1] && item.manageTimeSlotVOS[jIndex + 1].startTime)
                                                    // maxTime:j.endTime || item.manageTimeSlotVOS[jIndex+1] && item.manageTimeSlotVOS[jIndex+1].startTime
                                                }">
                                        </el-time-select>
                                        <div class="mo-input--number" style="width: 150px; display: inline-flex;">
                                            <el-input-number :min="0" :max="9999999" :controls="false"
                                                v-model="j.money"></el-input-number>
                                            <div class="define-append">元</div>
                                        </div>
                                        <el-button type="text" @click.stop="deleteTimeQuetum(index, jIndex)"
                                            style="position: absolute; right: -136px;">删除时段
                                        </el-button>
                                        <el-button type="text" @click="addTimeQuetum(index, jIndex)"
                                            style="position: absolute; left: -5px; bottom: -30px;">添加时段
                                        </el-button>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="特殊开放时间">
                        <div class="block" style="margin-bottom: 10px;">
                            <el-date-picker v-model="ruleForm.openTime" type="date" placeholder="选择日期"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                            <el-button type="primary" plain @click="addspecialWeeksList">新增日期</el-button>
                        </div>
                        <el-collapse v-model="specialTime" accordion>
                            <el-collapse-item v-for="(item, index) in specialWeeks" :key="index" :name="index">
                                <template slot="title">
                                    {{ item.openTime }}
                                    <el-button type="text" @click.stop="handleDelete(index)"
                                        style="position: absolute; right: 10px;">删除日期</el-button>
                                </template>
                                <div style="position: relative;">
                                    <div style="margin-top: 3px;" v-for="(j, jIndex) in item.manageTimeSlotVOS"
                                        :key="jIndex + 'j'">
                                        <el-time-select style="width: 180px;" placeholder="起始时间" v-model="j.startTime"
                                            :clearable="false" :editable="false" :picker-options="{
                                                    start: '08:00',
                                                    step: '00:30',
                                                    end: '22:00',
                                                    minTime: getMinTime(item.manageTimeSlotVOS[jIndex - 1] && item.manageTimeSlotVOS[jIndex - 1].endTime),
                                                    maxTime: j.endTime
                                                }">
                                        </el-time-select>
                                        <el-time-select style="width: 180px; " placeholder="结束时间" v-model="j.endTime"
                                            :clearable="false" :editable="false" :picker-options="{
                                                    start: '08:00',
                                                    step: '00:30',
                                                    end: '22:00',
                                                    minTime: j.startTime || item.manageTimeSlotVOS[jIndex - 1] && item.manageTimeSlotVOS[jIndex - 1].endTime,
                                                    maxTime: getMaxTime(item.manageTimeSlotVOS[jIndex + 1] && item.manageTimeSlotVOS[jIndex + 1].startTime)
                                                }">
                                        </el-time-select>
                                        <div class="mo-input--number" style="width: 150px; display: inline-flex;">
                                            <el-input-number :min="0" :max="9999999" :controls="false"
                                                v-model="j.money"></el-input-number>
                                            <div class="define-append">元</div>
                                        </div>
                                        <el-button type="text" style="position: absolute; right: -136px;"
                                            @click="deleteTimeQuetum1(index, jIndex)">删除时段
                                        </el-button>
                                        <el-button type="text" style="position: absolute; left: -5px; bottom: -30px;"
                                            @click="addTimeQuetum1(index, jIndex)">添加时段
                                        </el-button>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            labelPosition: 'top',
            conventionTime: '-1',
            specialTime: '-1',
            value: '',
            startTime: '',
            endTime: '',
            input2: '',
            managedWeeks: [],
            weeks: [
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周一',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周二',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周三',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周四',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周五',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周六',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周日',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
            ],
            specialWeeks: [],
            ruleForm: {
                name: '',
                delivery: false,
                type: [],
                resource: '',
                desc: '',
                RegularOpoen: true,
                openTime: '',
                radioSiteType: '',
                cardName: '',
                value: false,
            },
            rules: {
                name: [
                    { required: true, message: '请选择场地类型', trigger: 'blur' }
                ],
                type: [
                    { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
                ],
                resource: [
                    { required: true, message: '请选择活动资源', trigger: 'blur' }
                ],
                desc: [
                    { required: true, message: '请填写活动形式', trigger: 'blur' }
                ],
                RegularOpoen: [
                    { required: true, message: '请填写活动形式', trigger: 'blur' }
                ],
                radioSiteType: [
                    { required: true, message: '请选择场地类型', trigger: 'blur' }
                ],
                cardName: [
                    { required: true, message: '请填写名称', trigger: 'blur' },
                    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
                ]
            }
        };
    },
    created() { },
    methods: {
        // 起始时间 时间段限制规则
        getMinTime(time) {
            if (time) {
                // 08:00 => mm =08, ss=00
                // 08:30 => mm =08, ss=30
                let mm = time.slice(0, 2)
                let ss = time.slice(3, 5)
                if (ss != 0) {
                    ss--
                    // console.log(`${mm}:${ss}`,'=====');
                    return `${mm}:${ss}`
                } else {
                    mm--
                    ss = 59
                    // console.log(`${mm}:${ss}`,'=====');
                    return `${mm}:${ss}`
                }
            }
            return ''
        },
        // 结束时间 时间段限制规则
        getMaxTime(time) {
            if (time) {
                let mm = time.slice(0, 2)
                let ss = time.slice(3, 5)
                    ss++
                    return `${mm}:${ss}`
            }
            return ''
        },
        addspecialWeeksList() {
            console.log(111);
            const addList = {
                id: '',
                cardId: '',
                openWeek: "",
                openTime: this.ruleForm.openTime,
                timeSlot: null,
                openTimeType: "special",
                status: false,
                createdBy: '',
                creationDate: '',
                lastUpdatedBy: '',
                lastUpdateDate: '',
                manageTimeSlotVOS: [
                    {
                        money: '',
                        startTime: '',
                        endTime: '',
                        timeSlot: '',
                    }
                ]
            }
            if (this.ruleForm.openTime !== '') {
                this.specialWeeks.push(addList);
                this.ruleForm.openTime = ''
            }
            console.log(this.specialWeeks, 'this.specialWeeks');
        },
        handleDelete(index) {
            this.specialWeeks.splice(index, 1);
        },
        // 常规开发时间段 增删
        deleteTimeQuetum(index, jIndex) {
            if (jIndex !== 0) {
                this.weeks[index].manageTimeSlotVOS.splice(jIndex, 1);
            } else {
                this.$message('第一条数据不可删噢,请修改~');
            }
        },
        addTimeQuetum(index, jIndex) {
            const addList = {
                startTime: '',
                endTime: '',
                timeSlot: '',
                money: ''
            }
            // this.weeks[index].manageTimeSlotVOS.push(addList)
            let manageTimeSlotVOSList = []
            this.weeks[index].manageTimeSlotVOS.map(item => {
                if (item.endTime == '' || item.startTime == '' || (item.money === undefined || item.money === null || item.money === '')) {
                    manageTimeSlotVOSList.push(item)
                }
            })
            if (manageTimeSlotVOSList.length == 0) {
                this.weeks[index].manageTimeSlotVOS.push(addList)
            } else {
                this.$message.error('您上一条数据还没编辑完哦~');
            }
        },
        // 特殊开发时间段 增删
        deleteTimeQuetum1(index, jIndex) {
            if (jIndex !== 0) {
                this.specialWeeks[index].manageTimeSlotVOS.splice(jIndex, 1);
            } else {
                this.$message('第一条数据不可删噢,请修改~');
            }
        },
        addTimeQuetum1(index, jIndex) {
            const addList = {
                startTime: '',
                endTime: '',
                timeSlot: '',
                money: ''
            }
            // this.specialWeeks[index].manageTimeSlotVOS.push(addList)
            let manageTimeSlotVOSList = []
            this.specialWeeks[index].manageTimeSlotVOS.map(item => {
                if (item.endTime == '' || item.startTime == '' || (item.money === undefined || item.money === null || item.money === '')) {
                    manageTimeSlotVOSList.push(item)
                }
            })
            if (manageTimeSlotVOSList.length == 0) {
                this.specialWeeks[index].manageTimeSlotVOS.push(addList)
            } else {
                this.$message.error('您上一条数据还没编辑完哦~');
            }
        },
        manageAllWeeks() {
            const allweeks = [...this.weeks, ...this.specialWeeks];
            return this.managedWeeks = allweeks.map((week) => {
                week.manageTimeSlotVOS = week.manageTimeSlotVOS.map((timeSlot) => {
                    if (timeSlot.startTime !== '' && timeSlot.endTime !== '') {
                        timeSlot.timeSlot = `${timeSlot.startTime}-${timeSlot.endTime}`
                    } else {
                        timeSlot.timeSlot = ''
                    }
                    return timeSlot
                })
                return week
            })
        }
    },
    watch: {
        managedWeeks: {
            handler() {
                console.log('数据变化了');
            },
            deep: true
        }
    },
}
</script>

<style lang="less" scoped>
.siteType {
    display: flex;
    width: 100%;

    p:first-child {
        margin-right: 15px;
    }

    p {
        width: 100px;
        padding: 10px 10px;
        border: 1px solid #DCDFE5;
        border-radius: 5px;
    }
}

::v-deep .el-collapse-item__arrow {
    margin: 0;
}

::v-deep .el-collapse-item__content {
    display: flex;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    margin-right: 6px;
}

::v-deep .el-form-item .el-form-item {
    display: flex;
}

::v-deep .el-form--label-top .el-form-item__label {
    margin-right: 11px;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
    -moz-appearance: textfield !important;
}
 /* 自定义数字输入框append  */
 .mo-input--number {
    border: 1px solid #DCDFE6;
    width: 100%;
    display: flex;
    border-radius: 4px;
    .el-input-number--mini{
      flex: 1;
    }
    ::v-deep .el-input__inner{
      border: none!important;
    }
    ::v-deep .el-input-number--medium {
        width: 110px;
    }
  }
 
  .define-append{
    width: 50px;
    display: inline-block;
    background: #F5F7FA;
    padding: 0px 3px;
    border-left: none;
    height: 36px;
    line-height: 36px;
    color: #909399;
    font-size: 12px;
    text-align: center;
  }

</style>
