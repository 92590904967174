<template>
    <div class="app-container stadium-siteconfig">
        <div class="filter-line">
            <span>
                <el-button type="primary" icon="el-icon-plus" @click="addSiteList">新增场地</el-button>
                <el-button @click="exportHandler">{{ this.$t("commons.export") }}</el-button>
                <el-button icon="el-icon-refresh-right" @click="getTableList">{{ this.$t("commons.refresh") }}</el-button>
            </span>
            <div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show"
                :class="dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'">
                <i class="iconfont icon-loudoutu"></i>筛选
            </div>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler">
                <template v-slot:OpeningHoursSlot="row">
                    <span v-if="row.datas.timeStr">
                        <span class="single-time-span">
                            <span>
                                {{ row.datas.timeStr }}
                            </span>
                        </span>
                    </span>
                </template>
            </finalTable>
        </div>
        <!-- 弹窗 新增场地-->
        <el-dialog title="新增场地" :visible.sync="dialogVisible" width="728px" :before-close="handleClose"
            :close-on-click-modal="false">
            <span>
                <detailed ref="detailedRef"></detailed>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="addDetailed">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 弹窗 编辑-->
        <el-dialog title="编辑场地" :visible.sync="dialogVisible1" width="728px" :before-close="handleClose"
            :close-on-click-modal="false">
            <span>
                <editDetailed ref="editDetailedRef" :detailsObj="detailsObj"></editDetailed>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="editButton">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { cloneDeep } from "lodash";
import finalTable from "@/components/FinalTable";
import {
    getScheduleList,
    editCardStatus,
    paymentListExport,
    paymentListDelete,
    paymentListAdd,
    getDetailList,
    paymentListEdit
} from "@/api/ruge/gsPark/customerService/siteConfig";
import detailed from "./detailed"
import editDetailed from "./editDetailed"
export default {
    name: "siteConfigComponent",
    components: {
        finalTable,
        detailed,
        editDetailed
    },
    data() {
        return {
            cloneDeepManageTimeVOS: [],
            cloneDeepManageTIMEVOS: [],
            detailsObj: {},
            dialogVisible: false,
            dialogVisible1: false,
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "siteName", label: "场地", width: "" },
                    { prop: "openTimeParam", label: "常规开放时间", width: "700" },
                    { prop: "siteStatus", label: "是否开放", width: "100" },
                    { prop: "operation", label: "操作", width: "110" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    siteName: {
                        type: "input",
                        label: "场地",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入场地",
                        prefixIcon: "el-icon-search",
                    },
                    openTimeParam: {
                        type: "input",
                        label: "常规开放时间",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入场地",
                        prefixIcon: "el-icon-search",
                    },
                    siteStatus: {
                        type: "selector",
                        label: "是否开发",
                        value: "",
                        actionType: "goSearch",
                        // optionList: [],
                        optionList: [
                            {
                                label: "是",
                                value: "ON",
                            },
                            {
                                label: "否",
                                value: "OFF",
                            },
                        ],
                    }
                },
                // 表格内容配置
                detailConfig: {
                    openTimeParam: {
                        type: "slot",
                        slotName: "OpeningHoursSlot",
                    },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "editClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "编辑",
                            },
                            {
                                actionType: "deleteClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "删除",
                            }
                        ],
                    },
                    siteStatus: {
                        type: "switch",
                        actionType: "switchEvent",
                    }
                },
                // 高级搜索配置
                advanceFilterConfig: {},
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            siteObj: {
                tableLoading: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    cardStatus: null,
                    cardType: null,
                    siteName: null,
                    siteStatus: null,
                    openTimeParam: null
                }
            }
        }
    },
    created() {
        this.getTableList()
    },
    methods: {
        // 初始化数据
        getTableList() {
            this.siteObj.tableLoading = true;
            const params = { ...this.siteObj.listQuery };
            for (let pn in params) {
                !params[pn] && params[pn] !== 0 && (params[pn] = null);
            }
            getScheduleList(params)
                .then((res) => {
                    res.rows.forEach((item) => {
                        item.siteStatus = item.siteStatus === "ON" ? "ENABLE" : "DISABLE";
                    });
                    console.log(res, '初始化数据');
                    console.log(this.dataset.tableData, ' this.dataset.tableData');
                    this.dataset.pageVO.total = res.total;
                    this.dataset.tableData = res.rows;
                    console.log(this.dataset.tableData, ' this.dataset.tableData');
                })
                .finally(() => {
                    this.siteObj.tableLoading = false;
                })
        },
        // table组件页面事件配置
        tableEventHandler(datas) {
            console.log(datas, 'datasdatasdatas');
            if (datas.type === "goSearch") {
                console.log(datas.params, 'datas.paramsdatas.params');
                this.siteObj.listQuery = {
                    ...this.siteObj.listQuery,
                    ...datas.params,
                };
                this.siteObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;

                this.getTableList()
            } else if (datas.type === "switchEvent") {
                this.updateEnableStatus(datas.row);
                console.log(datas.row, 'row');
            } else if (datas.type === "deleteClick") {
                this.$confirm('是否确认删除？', '提示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(success => {
                        this.deleteSeleteRow(datas.row.id);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else if (datas.type === "editClick") {
                this.dialogVisible1 = true
                getDetailList({
                    id: datas.row.id
                }).then((res) => {
                    console.log(res, '编辑按钮');
                    this.detailsObj = res
                })
            } else if (datas.type === "paginationChange") {
                // 配置分页
                this.siteObj.listQuery.current = datas.params.current.page;
                this.siteObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            }
        },
        // 是否已开放 按钮 状态配置
        updateEnableStatus({ id, siteStatus }) {
            const emuMap = {
                ENABLE: "ON",
                DISABLE: "OFF",
            };
            siteStatus;
            editCardStatus({
                id,
                state: emuMap[siteStatus],
            }).then((res) => {
                if (res) {
                    this.$message.success("修改状态成功！");
                    this.getTableList();
                }
            });
        },
        // 导出
        exportHandler() {
            paymentListExport(this.siteObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "场地配置",
                    taskStatus: 0,
                    rootPath: "customerGSPath",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
        // 删除功能
        deleteSeleteRow(id) {
            paymentListDelete({
                id: id
            }).then((res) => {
                if (res.code === 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    })
                }
                this.siteObj.listQuery.current = 1;
                this.getTableList()
            })
        },
        // 新增功能
        addSiteList() {
            this.dialogVisible = true
        },
        // 新增弹窗 确认按钮
        addDetailed() {
            this.$refs["detailedRef"].$refs.ruleForm.validate((valid) => {
                const arrList = this.$refs["detailedRef"].manageAllWeeks()
                const arrList1 = arrList.map(({ openWeek, openTime, openTimeType, manageTimeSlotVOS }) => ({ openWeek, openTime, openTimeType, manageTimeSlotVOS }))
                // console.log(arrList1, 'arrList1');
                this.cloneDeepManageTimeVOS = cloneDeep(arrList1);
                console.log(this.cloneDeepManageTimeVOS, 'this.cloneDeepManageTimeVOS');
                let filteredArr = this.cloneDeepManageTimeVOS.map(obj => {
                    let slots = obj.manageTimeSlotVOS.filter(slot => slot.startTime !== null && slot.endTime !== null && slot.startTime !== "" && slot.endTime !== "" && slot.timeSlot !== "" && slot.money !== "");
                    return {
                        ...obj,
                        manageTimeSlotVOS: slots
                    };
                }).filter(obj => obj.manageTimeSlotVOS.length > 0);

                console.log(filteredArr, 'filteredArr');
                console.log(this.$refs["detailedRef"].weeks, 'this.$refs["detailedRef"].weeks');
                if (valid) {
                    let ruleFormList = {
                        siteName: this.$refs["detailedRef"].ruleForm.cardName,
                        siteType: this.$refs["detailedRef"].ruleForm.radioSiteType === '2' ? "tennis" : "basketball",
                        siteStatus: this.$refs["detailedRef"].ruleForm.value === true ? "ON" : "OFF",
                        manageTimeVOS: filteredArr
                    }
                    let newWeeks = [
                        {
                            openTimeType: 'normal',
                            openTime: '',
                            openWeek: '周一',
                            status: false,
                            money: '',
                            manageTimeSlotVOS: [
                                {
                                    startTime: '',
                                    endTime: '',
                                    timeSlot: '',
                                    money: ''
                                }
                            ]

                        },
                        {
                            openTimeType: 'normal',
                            openTime: '',
                            openWeek: '周二',
                            status: false,
                            money: '',
                            manageTimeSlotVOS: [
                                {
                                    startTime: '',
                                    endTime: '',
                                    timeSlot: '',
                                    money: ''
                                }
                            ]

                        },
                        {
                            openTimeType: 'normal',
                            openTime: '',
                            openWeek: '周三',
                            status: false,
                            money: '',
                            manageTimeSlotVOS: [
                                {
                                    startTime: '',
                                    endTime: '',
                                    timeSlot: '',
                                    money: ''
                                }
                            ]

                        },
                        {
                            openTimeType: 'normal',
                            openTime: '',
                            openWeek: '周四',
                            status: false,
                            money: '',
                            manageTimeSlotVOS: [
                                {
                                    startTime: '',
                                    endTime: '',
                                    timeSlot: '',
                                    money: ''
                                }
                            ]

                        },
                        {
                            openTimeType: 'normal',
                            openTime: '',
                            openWeek: '周五',
                            status: false,
                            money: '',
                            manageTimeSlotVOS: [
                                {
                                    startTime: '',
                                    endTime: '',
                                    timeSlot: '',
                                    money: ''
                                }
                            ]

                        },
                        {
                            openTimeType: 'normal',
                            openTime: '',
                            openWeek: '周六',
                            status: false,
                            money: '',
                            manageTimeSlotVOS: [
                                {
                                    startTime: '',
                                    endTime: '',
                                    timeSlot: '',
                                    money: ''
                                }
                            ]

                        },
                        {
                            openTimeType: 'normal',
                            openTime: '',
                            openWeek: '周日',
                            status: false,
                            money: '',
                            manageTimeSlotVOS: [
                                {
                                    startTime: '',
                                    endTime: '',
                                    timeSlot: '',
                                    money: ''
                                }
                            ]

                        },
                    ]
                    // console.log(ruleFormList, 'ruleFormList');
                    paymentListAdd(ruleFormList).then((res) => {
                        this.dialogVisible = false
                        // 清空数据
                        this.$refs["detailedRef"].$refs.ruleForm.resetFields();
                        if (res.code === 200) {
                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'warning'
                            })
                        }
                        this.$refs["detailedRef"].weeks = newWeeks
                        this.$refs["detailedRef"].specialWeeks = []
                        this.$refs["detailedRef"].conventionTime = "-1"
                        this.$refs["detailedRef"].specialTime = "-1"
                        this.getTableList()
                    })
                }
            })
        },
        // 编辑弹窗 确认按钮
        editButton() {
            this.$refs["editDetailedRef"].$refs.ruleForm.validate((valid) => {
                const arrList = this.$refs["editDetailedRef"].manageAllWeeks()
                console.log(arrList, 'arrList');
                const arrList1 = arrList.map(({ openWeek, openTime, openTimeType, manageTimeSlotVOS }) => ({ openWeek, openTime, openTimeType, manageTimeSlotVOS }))
                console.log(arrList1, 'arrList1');
                this.cloneDeepManageTIMEVOS = cloneDeep(arrList1);
                console.log(this.cloneDeepManageTIMEVOS, 'this.cloneDeepManageTIMEVOS');
                let filteredArr = this.cloneDeepManageTIMEVOS.map(obj => {
                    let slots = obj.manageTimeSlotVOS.filter(slot => slot.startTime !== null && slot.endTime !== null && slot.startTime !== "" && slot.endTime !== "" && slot.timeSlot !== "" && slot.money !== "");
                    return {
                        ...obj,
                        manageTimeSlotVOS: slots
                    };
                }).filter(obj => obj.manageTimeSlotVOS.length > 0);
                let newWeeks = [
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周一',
                        status: false,
                        money: '',
                        manageTimeSlotVOS: [
                            {
                                startTime: '',
                                endTime: '',
                                timeSlot: '',
                                money: ''
                            }
                        ]

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周二',
                        status: false,
                        money: '',
                        manageTimeSlotVOS: [
                            {
                                startTime: '',
                                endTime: '',
                                timeSlot: '',
                                money: ''
                            }
                        ]

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周三',
                        status: false,
                        money: '',
                        manageTimeSlotVOS: [
                            {
                                startTime: '',
                                endTime: '',
                                timeSlot: '',
                                money: ''
                            }
                        ]

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周四',
                        status: false,
                        money: '',
                        manageTimeSlotVOS: [
                            {
                                startTime: '',
                                endTime: '',
                                timeSlot: '',
                                money: ''
                            }
                        ]

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周五',
                        status: false,
                        money: '',
                        manageTimeSlotVOS: [
                            {
                                startTime: '',
                                endTime: '',
                                timeSlot: '',
                                money: ''
                            }
                        ]

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周六',
                        status: false,
                        money: '',
                        manageTimeSlotVOS: [
                            {
                                startTime: '',
                                endTime: '',
                                timeSlot: '',
                                money: ''
                            }
                        ]

                    },
                    {
                        openTimeType: 'normal',
                        openTime: '',
                        openWeek: '周日',
                        status: false,
                        money: '',
                        manageTimeSlotVOS: [
                            {
                                startTime: '',
                                endTime: '',
                                timeSlot: '',
                                money: ''
                            }
                        ]

                    },
                ]
                // console.log(filteredArr, 'filteredArr');
                if (valid) {
                    let ruleFormList = {
                        id: this.detailsObj.id,
                        siteName: this.$refs["editDetailedRef"].ruleForm.siteName,
                        siteType: this.$refs["editDetailedRef"].ruleForm.siteType,
                        siteStatus: this.$refs["editDetailedRef"].ruleForm.siteStatus,
                        manageTimeVOS: filteredArr
                    }
                    // console.log(ruleFormList, 'ruleFormList');
                    paymentListEdit(ruleFormList).then((res) => {
                        this.dialogVisible1 = false
                        if (res.code === 200) {
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'warning'
                            })
                        }
                        this.$refs["editDetailedRef"].current = 1
                        this.$refs["editDetailedRef"].$refs.ruleForm.resetFields();
                        this.$refs["editDetailedRef"].weeks = newWeeks
                        this.$refs["editDetailedRef"].specialWeeks = []
                        this.$refs["editDetailedRef"].conventionTime = "-1"
                        this.$refs["editDetailedRef"].specialTime = "-1"
                        this.getTableList()
                    })
                }
            })
            this.$refs["editDetailedRef"].flag = false
        },
        // 弹窗关闭配置
        handleClose(done) {
            this.dialogVisible = false;
            this.dialogVisible1 = false;
            let newWeeks = [
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周一',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周二',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周三',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周四',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周五',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周六',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
                {
                    openTimeType: 'normal',
                    openTime: '',
                    openWeek: '周日',
                    status: false,
                    money: '',
                    manageTimeSlotVOS: [
                        {
                            startTime: '',
                            endTime: '',
                            timeSlot: '',
                            money: ''
                        }
                    ]

                },
            ]
            if (this.$refs["detailedRef"]) {
                this.$refs["detailedRef"].$refs.ruleForm.resetFields();
                this.$refs["detailedRef"].weeks = newWeeks
                this.$refs["detailedRef"].specialWeeks = []
                this.$refs["detailedRef"].conventionTime = "-1"
                this.$refs["detailedRef"].specialTime = "-1"
            }
            if (this.$refs["editDetailedRef"]) {
                this.$refs["editDetailedRef"].flag = false
                this.$refs["editDetailedRef"].current = 1
                console.log(this.$refs["editDetailedRef"].weeks, 'this.$refs["editDetailedRef"].weeks');
                this.$refs["editDetailedRef"].$refs.ruleForm.resetFields();
                this.$refs["editDetailedRef"].weeks = newWeeks
                this.$refs["editDetailedRef"].specialWeeks = []
                this.$refs["editDetailedRef"].conventionTime = "-1"
                this.$refs["editDetailedRef"].specialTime = "-1"
            }
        },
    },
    watch: {
        siteObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        },
    }
}
</script>
<style lang="less" scoped>
.stadium-siteconfig {
    .filter-line {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            height: 36px;
        }

        .icon-loudoutu {
            font-size: 12px;
        }

        .header-filter-button {
            cursor: pointer;
            width: 84px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .filter-button-open {
            color: #409eff;
            background: #ecf5ff;
            border: 1px solid #c6e2ff;
        }

        .filter-button-close {
            color: #2a4158;
            background: #ffffff;
            border: 1px solid #e7e8eb;
        }
    }

    :deep(.el-dialog) {
        height: 100%;

        .el-dialog__body {
            max-height: calc(100% - 154px);
            overflow-y: auto;
        }
    }
}
</style>