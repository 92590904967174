import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**
 * 场地管理 - 场地列表
 * @param params
 */
export function getScheduleList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/queryList',
        method: 'get',
        params,
    });
}
/**
 * 场地管理 - 启用禁用
 * @param params
 */
export function editCardStatus(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/editDisable',
        method: 'get',
        params,
    });
}
/**
 * 场地管理  - 导出功能
 * @param params
 */
export function paymentListExport(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/export',
        method: 'get',
        params,
    });
}
/**
 * 场地管理  - 删除功能
 * @param params
 */
export function paymentListDelete(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/delete',
        method: 'get',
        params,
    });
}
/**
 * 场地管理  - 新增功能
 * @param params
 */
export function paymentListAdd(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/add',
        method: 'post',
        data:params,
    });
}
/**
 * 场地管理  - 编辑功能
 * @param params
 */
export function paymentListEdit(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/edit',
        method: 'post',
        data:params,
    });
}
/**
 * 场地管理  - 获取详情
 * @param params
 */
export function getDetailList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/getDetails',
        method: 'get',
        params,
    });
}
/**
 * 场地管理  - 获取历史特殊日期
 * @param params
 */
export function getHistoryList(params){
    return request({
        url: envInfo.bgApp.customerGSPath + '/site/manage/queryHistorySpecialDate',
        method: 'get',
        params,
    });
}